.container {
  min-width: 114px;
  height: 34px;
  outline: none;
  border-radius: var(--cornerradius6);
  cursor: pointer;
  background-color: transparent;
  border: 2px solid var(--cst-advertisingbutton-outline-default);
  &:hover {
    border: 2px solid var(--cst-advertisingbutton-outline-hover);
  }
  &:focus-visible {
    border: 2px solid var(--cst-advertisingbutton-outline-focused);
  }
  &-disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  &:active {
    border: 2px solid var(--cst-advertisingbutton-outline-pressed);
  }
  & span {
    color: var(--cst-advertisingbutton-text-default);
  }
}
