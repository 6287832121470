@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;
@use '@button' as *;

.close-button {
  border-radius: var(--cornerradius6);

  @include singleFixedSize(32px);
  @include buttonSizes('32', '38', '34', '38', '38', '38');

  @include min-428-break {
    @include singleFixedSize(38px);
    border-radius: var(--cornerradius6);
  }

  @include min-1024-break {
    @include singleFixedSize(34px);
    border-radius: var(--cornerradius6);
  }

  @include min-1440-break {
    @include singleFixedSize(38px);
    border-radius: var(--cornerradius8);
  }

  .icon-black {
    color: var(--global-grey900);

    @include singleFixedSize(16px);

    @include min-428-break {
      @include singleFixedSize(18px);
    }

    @include min-1024-break {
      color: #6941c6;
      @include singleFixedSize(16px);
    }

    @include min-1440-break {
      @include singleFixedSize(18px);
    }
  }
}
