@use '../../../app/queries' as *;
@use '../../../shared/styles/fixed' as *;
@use '../../../shared/ui/typography/sizes' as *;
@use '../../../shared/ui/button/adaptive' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 10px;
  min-width: 308px;
  width: 100%;
  max-width: 100%;
  border: 2px solid var(--cst-toast-outline);
  box-shadow: var(--elevation-1);
  max-height: max-content;
  background: var(--cst-toast-background);

  @include min-428-break {
    min-width: 412px;
  }

  @include min-1024-break {
    min-width: 304px;
    max-width: 304px;
  }

  @include min-1440-break {
    min-width: 418px;
    padding: 14px;
  }

  .top-slot {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 14px;

    &__left {
      display: flex;
      flex: 1;
      gap: 8px;

      @include min-428-break {
        gap: 10px;
      }

      @include min-1024-break {
        gap: 12px;
      }

      @include min-1024-break {
        gap: 14px;
      }

      &-icon {
        .background-icon {
          @include singleFixedSize(32px);

          @include min-428-break {
            @include singleFixedSize(40px);
          }
        }
      }

      &-text-block {
        display: flex;
        flex-direction: column;
        gap: 2px;
        flex: 1;

        @include min-1024-break {
          gap: 4px;
        }

        &-title {
          color: var(--cst-toast-title);

          @include typography-s;
          @include weight-semi-bold;

          @include min-428-break {
            @include typography-base;
            @include weight-semi-bold;
          }

          @include min-1024-break {
            @include typography-s;
            @include weight-semi-bold;
          }

          @include min-1440-break {
            @include typography-m;
            @include weight-semi-bold;
          }
        }

        &-subtitle {
          max-width: 286px;

          color: var(--cst-toast-description);

          @include typography-xs;
          @include weight-medium;

          @include min-428-break {
            @include typography-s;
            @include weight-medium;
          }

          @include min-1024-break {
            @include typography-xs;
            @include weight-medium;
          }

          @include min-1440-break {
            @include typography-base;
            @include weight-medium;
          }
        }
      }
    }

    &__right {
      width: max-content;
      height: max-content;

      &-close-btn {
        display: none;
        justify-content: center;
        align-items: center;

        padding: 8px;
        border-radius: 6px;

        position: relative;

        @include min-1024-break {
          display: flex;

          .x-close {
            @include singleFixedSize(10px);
            stroke-width: 2px;
            position: absolute;
            transform: initial;
          }
        }
        @include singleFixedSize(26px);

        @include min-1440-break {
          @include singleFixedSize(36px);

          .x-close {
            @include singleFixedSize(18px);
            position: initial;

            transform: initial;
          }
        }
      }
    }
  }

  .bottom-slot {
    button {
      border-radius: 6px;

      @include buttonSizes('32', '42', '42', '32', '38', '38');

      span {
        @include typography-xs;
        @include weight-semi-bold;
      }

      @include min-428-break {
        span {
          @include typography-base;
          @include weight-semi-bold;
        }
      }

      @include min-1024-break {
        span {
          @include typography-s;
          @include weight-semi-bold;
        }
      }

      @include min-1440-break {
        border-radius: 8px;
        span {
          @include typography-s;
          @include weight-semi-bold;
        }
      }
    }
  }
}
