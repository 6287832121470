@use '@queries' as *;

.notifications {
  position: fixed;
  z-index: 9999;
  bottom: 56px;
  right: 0;
  left: 0;
  gap: 6px;
  //border: 1px solid black;
  margin: 0;
  padding: 0 6px;

  display: flex;
  flex-direction: column;

  list-style: none;
  max-height: max-content;

  @include min-428-break {
    bottom: 68px;
    gap: 8px;
    padding: 0 8px;
  }

  @include min-744-break {
    bottom: 88px;
    gap: 10px;
    padding: 0 10px;
    align-items: flex-end;
  }

  @include min-1024-break {
    bottom: 10px;
    left: 10px;
    right: initial;
    gap: 12px;
  }

  @include min-1440-break {
    bottom: 20px;
    left: 20px;
    right: initial;
    gap: 12px;
  }
  @include min-1920-break {
    bottom: 32px;
    left: 32px;
    right: initial;
    gap: 12px;
  }

  .item {
    margin: 0;
    padding: 0;
    position: relative;

    @include min-744-break {
      max-width: 412px;
    }
  }
}
